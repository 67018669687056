import { createStyles } from "@mui/styles"
import { primaryColor, primaryColor50 } from "./colors"

const styles = createStyles({
  hasPaddingTop: {
    paddingTop: "10px",
  },
  hasTextCentered: {
    textAlign: "center",
  },
  isBold: {
    fontWeight: "600",
  },
  hasShadow: {
    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.15)",
  },
  isPrimary: {
    color: primaryColor,
    "&hover": {
      color: primaryColor50,
    },
  },
  isSmall: {
    fontSize: "0.75em",
  },
})

export default styles