import { createStyles } from "@mui/styles";
import { whiteColor } from "./colors";

const conatinerFluid = {
  paddingRight: "15px",
  paddingLeft: "15px",
  marginRight: "auto",
  marginLeft: "auto",
  width: "100%",
};

const styles = createStyles({
  container: {
    ...conatinerFluid,
    "@media (min-width: 576px)": {
      maxWidth: "540px",
    },
    "@media (min-width: 768px)": {
      maxWidth: "720px",
    },
    "@media (min-width: 992px)": {
      maxWidth: "960px",
    },
    "@media (min-width: 1200px)": {
      maxWidth: "1140px",
    },
  },
  contentContainer: {
    backgroundColor: whiteColor,
    padding: 10,
    marginTop: 20,
    borderRadius: 5,
    paddingBottom: 30,
  },
  rowGrey: {
    backgroundColor: "rgb(245, 245, 245)",
    backgroundSize: "cover",
    backgroundPosition: "bottom center",
    paddingBottom: 0,
  },
})

export default styles