import { createStyles } from "@mui/styles"
import { blackColor, greyIconColor } from "./colors"



const styles = createStyles({
  signUpBox: {
    backgroundColor: "rgba(255,255,255,0.92)",
    color: blackColor,
    padding: "1em",
    borderRadius: "6px",
    marginTop: "0",
  },
  form: {
    margin: "0"
  },
  inputIconsColor: {
    color: greyIconColor
  }
}) 

export default styles