'use client';
import { Container } from '@sugar/landingpage-components';
import backgroundImage from 'assets/images/blur_bg.jpg';
import { PropsWithChildren } from 'react';

export function ClientContainer(props: PropsWithChildren) {
  return (
    <Container
      backgroundImage={backgroundImage.src}
      backgroundStyles={{ minHeight: 'calc(100vh - 102px)' }}
    >
      {props.children}
    </Container>
  );
}
