'use client';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { FormEvent, MouseEvent, useEffect, useState } from 'react';
import classNames from 'classnames';
import { InputAdornment, Typography } from '@mui/material';
import { Email, Lock as LockIcon } from '@mui/icons-material';
import layout from 'assets/styles/layout';
import utils from 'assets/styles/utils';
import form from 'assets/styles/form';
import { makeStyles } from '@mui/styles';
import { ContentBox } from '@sugar/landingpage-components';

type LoginError =
  | 'oauth2_error'
  | 'login_failed'
  | 'please_verify'
  | 'generic'
  | 'email_invalid'
  | 'banned';
type ResendError = 'email_not_found' | 'threshold_reached' | 'generic';

const useStyles = makeStyles({
  ...layout,
  ...utils,
  ...form,
});

interface Props {
  showError?: boolean;
  showVerifiedBar?: boolean;
  showLogoutBar?: boolean;
  type: '0' | '1';
  challenge: string;
}

export function LoginForm(props: Props) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<LoginError | ''>('');
  const [sentryId, setsentryId] = useState<''>('');
  const [resendError, setResendError] = useState<ResendError | ''>('');
  const [resendSuccess, setResendSuccess] = useState<boolean>(false);

  const classes = useStyles();

  useEffect(() => {
    if (props.showError) {
      setError('oauth2_error');
    }
  }, [props.showError]);

  useEffect(() => {
    if (props.showVerifiedBar) {
      const windowRef = window as any;

      const mapEventToGoal: { [k: string]: number } = {
        funnel_reg_success: 12,
      };

      windowRef._paq = windowRef._paq || [];
      windowRef._paq.push(['trackGoal', mapEventToGoal['funnel_reg_success']]);

      windowRef._mtm = windowRef._mtm || [];
      windowRef._mtm.push({
        event: 'funnel_reg_success',
        gender: props.type === '0' ? 'm' : 'f',
      });

      windowRef.dataLayer = windowRef.dataLayer || [];
      windowRef.dataLayer.push({
        event: 'funnel_reg_success',
        gender: props.type === '0' ? 'm' : 'f',
      });
    }
  }, [props.showVerifiedBar]);

  async function resendVerificationMail(event: MouseEvent) {
    event.preventDefault();
    setResendSuccess(false);
    setError('');

    const response = await fetch('/api/resendVerificationMail', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email }),
    });
    if (response.status === 200) {
      setResendSuccess(true);
    } else {
      const body = await response.json();
      setResendError(body.error);
    }
  }

  async function handleSubmit(event: FormEvent) {
    event.preventDefault();
    const url = '/api/login';
    setError('');
    setResendError('');
    setResendSuccess(false);

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email: email.trim(),
          password,
          challenge: props.challenge,
        }),
      });
      if (response.status === 200) {
        const res = await response.json();

        window.location = res.redirect;
        //here we will get a redirect url in future
      } else {
        const res = await response.json();
        setError(res.error);
        if (res.sentryId) {
          setsentryId(res.sentryId);
        }
      }
    } catch (error) {
      setError('generic');
    }
  }

  return (
    <Grid
      container
      justifyContent="center"
      spacing={5}
    >
      <Grid item xs={12} sm={6} lg={4}>
        <ContentBox ownerState={{styles: {marginBottom: 0}}}>
          <form className={classes.form} onSubmit={handleSubmit}>
            <input type="hidden" name="challenge" value={props.challenge} />

            {props.showLogoutBar && (
              <Alert severity="success">
                Du hast dich erfolgreich ausgeloggt
              </Alert>
            )}
            {props.showVerifiedBar && (
              <Alert severity="success">
                Deine E-Mail-Adresse wurde erfolgreich bestätigt
              </Alert>
            )}
            {resendSuccess && (
              <Alert severity="success">
                Eine E-Mail mit Bestätigunglink wurde dir soeben neu an dein
                Postfach versendet
              </Alert>
            )}
            <Typography variant="h4">
              Jetzt einloggen & losflirten
            </Typography>
            <FormControl fullWidth margin="normal">
              <TextField
                label="E-Mail"
                id="email"
                fullWidth
                variant="standard"
                onChange={(event) => setEmail(event.target.value)}
                value={email}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Email />
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>

            <FormControl fullWidth margin="normal">
              <TextField
                label="Passwort"
                id="pass"
                type="password"
                fullWidth
                autoComplete="off"
                variant="standard"
                onChange={(event) => setPassword(event.target.value)}
                value={password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <LockIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            <div style={{textAlign: 'center'}}>
              <Button
                color="primary"
                type="submit"
                variant="contained"
                style={{
                  marginTop: '1em',
                }}
              >
                Jetzt einloggen
              </Button>
            </div>
            <div style={{ textAlign: 'center' }}>
              <Link href="/reset">
                <small
                  style={{ fontSize: '0.75em', cursor: 'pointer' }}
                  className={classes.isPrimary}
                >
                  Passwort vergessen
                </small>
              </Link>
            </div>
            {resendError && (
              <Alert severity="error">
                Die E-Mail mit Bestätigungslink konnte nicht gesendet werden
              </Alert>
            )}
            {error === 'login_failed' && (
              <Alert severity="error">
                Passwort falsch oder unbekannte E-Mailadresse. Wenn du dein
                Passwort vergessen hast, kannst du&nbsp;
                <Link href="/reset">
                  <a className={classes.isPrimary}>hier</a>
                </Link>{' '}
                ein neues anfordern
              </Alert>
            )}

            {error === 'banned' && (
              <Alert severity="error">
                Dein Konto wurde gesperrt. Wenn du denkst, dass es sich um ein
                Irrtum handelt, wenn dich an{' '}
                <Link href="mailto:support@getasugar.de">
                  <a className={classes.isPrimary}>support@getasugar.de</a>
                </Link>
              </Alert>
            )}

            {error === 'email_invalid' && (
              <Alert severity="error">
                Bitte melde dich mit deiner E-Mailadresse an oder prüfe die
                eingegebene E-Mailadresse auf Fehler (bspw. ein fehlendes @ oder
                ein fehlender Punkt)
              </Alert>
            )}

            {error === 'please_verify' && (
              <Alert
                severity="error"
                style={{
                  whiteSpace: 'pre-wrap',
                }}
              >
                Deine E-Mailadresse wurde noch nicht bestätigt. Bitte schau in
                deinem Postfach oder ggf. deinem Spam Ordner nach und aktiviere
                dein Konto. &nbsp;
                <a
                  className={classes.isPrimary}
                  style={{
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                  onClick={resendVerificationMail}
                >
                  Hier
                </a>{' '}
                kannst du die Mail neu anfordern.
              </Alert>
            )}

            {['hydra'].includes(error) && (
              <Alert severity="error">
                Leider ist der Login abgelaufen. Aus Sicherheitsgründen
                begrenzen wir die Zeit, die für den Anmeldeprozess zur Verfügung
                steht. Bitte starte den Anmeldevorgang erneut.{' '}
                <Link
                  href={process.env.NEXT_PUBLIC_FRONTEND_BASEURL + '/login'}
                >
                  <a className={classes.isPrimary}>Neuer Login</a>
                </Link>
              </Alert>
            )}

            {['oauth2_error', 'generic'].includes(error) && (
              <Alert severity="error">
                Es ist ein Fehler beim Login aufgetreten. Bitte wende dich mit
                deinem Nutzernamen oder deiner E-Mailadresse und weiteren
                Informationen an{' '}
                <Link
                  href={`mailto:support@getasugar.de?subject=Problem%20mit%20dem%20Login&body=Liebes%20Support-Team%2C%0D%0A%0D%0Aes%20ist%20ein%20allgemeiner%20Fehler%20aufgetreten.%20Bitte%20schaut%20euch%20das%20mal%20an.%0D%0A%0D%0ACode%3A%20${error}%0D%0AFehler%3A%20${sentryId}%0D%0A%0D%0ABitte%20lasse%20die%20oben%20aufgef%C3%BChrten%20Codes%20so%20stehen.%20Sie%20helfen%20uns%20deutlich%20in%20der%20Fehleranalyse.%20Im%20Folgenden%20kannst%20du%20uns%20weitere%20Details%20zu%20deinem%20Fehler%20oder%20Anliegen%20nennen.`}
                >
                  <a className={classes.isPrimary}>support@getasugar.de</a>
                </Link>
              </Alert>
            )}
          </form>
        </ContentBox>

        <p style={{ marginBottom: '5em', textAlign: 'center', fontSize: '0.75em' }}>
          <Link href="/register" style={{ textDecoration: 'none' }}>
            <span className={classes.isPrimary}>
              Neu? <u>Hier</u> kostenlos registrieren
            </span>
          </Link>
        </p>
      </Grid>
    </Grid>
  );
}
